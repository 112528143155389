<template>
  <router-view/>
</template>

<style lang="scss">
body {
  margin: 0;
}

#app {
  height: 100vh;
  background-image: url("assets/main-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
